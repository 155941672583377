const awsconfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_ZIvWmL43c",
  aws_user_pools_web_client_id: "1vss60o14fqtepppss52gc9gs",
  // loginWith: {
  //   // OPTIONAL - Hosted UI configuration
  //   oauth: {
  //     domain: 'https://namuy-prueba-consola.auth.us-east-1.amazoncognito.com/oauth2/authorize',
  //     scopes: [
  //       'aws.cognito.signin.user.admin'
  //     ],
  //     responseType: 'token'
  //   }
  // }
};

export default awsconfig;
