import { useEffect, useState } from "react";

export default function BoxTranslater({
  nameLanguage,
  id,
  readOnly,
  onValueChange,
  resetForm,
}) {
  const [value, setValue] = useState("");

  const handleChange = (event) => {
    if (!readOnly) {
      setValue(event.target.value);
      onValueChange(id, event.target.value);
    }
  };

  useEffect(() => {
    if (resetForm) {
      setValue("");
    }
  }, [resetForm]);

  useEffect(() => {
    if (id === "gum") {
      const formatted = value.replace(/o/g, "ѳ");
      setValue(formatted);
    }
  }, [value, id]);

  return (
    <div className="flex flex-col mt-12">
      <h2 className="flex font-bold py-2">{nameLanguage}</h2>
      <input
        name="box-translater"
        id={id}
        cols="30"
        rows="10"
        className="py-6 sm:w-[100%] sm:h-[12vh] border border-sky-700 rounded-lg p-2 resize-none"
        readOnly={readOnly}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}
