import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function TeamAyte({ nombre, rol, ciudad, imagen }) {
  return (
    <div className="">
      <CardMedia
        component="img"
        image={imagen}
        className="rounded-full"
      />
      <CardContent className="flex flex-col items-center justify-center text-center">
        <Typography gutterBottom variant="h5" component="div">
          {nombre}
        </Typography>
        <Typography variant="body2">{rol}</Typography>
        <Typography variant="body2">{ciudad}</Typography>
      </CardContent>
    </div>
  );
}
