import TextBox from "../../components/verifier/text-box";
import Pagination from "../../components/verifier/pagination";
import SelectPerPage from "../../components/verifier/select-perPage";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getIndexList } from "../../redux/Actions/index.actions";
import { deleteItem } from "../../redux/Reducers/index.reducer";
import { Vortex } from "react-loader-spinner";
import { InfoPage } from "../../components/initial/info-page";

export default function Verifier() {
  const { listPending, count } = useSelector((state) => state.index);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [pendingList, setPendingList] = useState(listPending);

  const totalPages = Math.ceil(count / perPage);
  const maxButtons = totalPages >= 5 ? 5 : totalPages;
  
  useEffect(() => {
    setLoading(true);
    dispatch(getIndexList({ per_page: perPage, current_page: page })).then(
      () => {
        setLoading(false);
      }
    );
  }, [perPage, page, dispatch]);

  useEffect(() => {
    setPendingList(listPending);
  }, [listPending]);

  const handleChangePage = (page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (selectedQuantity) => {
    setPerPage(selectedQuantity);
    setPage(1);
  };

  const handleDelete = (id) => {
    const filtered = pendingList.filter((item) => item.id !== id);
    setPendingList(filtered);
    dispatch(deleteItem());

    // const newCount = count - 1;
    // const newTotalPages = Math.ceil(newCount / perPage);

    if (filtered.length % perPage === 0 && page > 1) {
      setPage(page - 1);
    }
  };

  if (loading) return <div></div>
  return (
    <main className="flex flex-col bg-rgb-text h-auto mt-2">
      {loading && (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-white opacity-75">
          <Vortex
            height="80"
            width="80"
            colors={[
              "#e62269",
              "#e62269",
              "#002d7b",
              "#002d7b",
              "#000000",
              "#d1d1d1",
            ]}
            ariaLabel="vortex-loading"
          />
        </div>
      )}
      <section className="flex flex-col space-y-1 mt-2 sm:mt-10 bg-zinc-50 p-3">
        <h1 className="flex justify-center text-center text-5xl">FRASES A VERIFICAR</h1>
        <section className="flex justify-center py-4 items-center">
          <InfoPage
            parraph={`Aqui se observan las traducciones creadas por la comunidad
              Guambiana y se deben verificar, si son correctas se da click en el `}
            phrase={"botón verde!"}
            mobileSize={3}
            laptopSize={5}
          />
        </section>
        <section className="overflow-x-auto overflow-hidden bg-zinc-200">
          <header 
            className="flex flex-row w-full py-4 sm:gap-x-10 text-lg px-10 gap-x-[54%] sm:gap-x-[36%] lg:px-28 lg:gap-x-[39%]"
          >
            <span className="font-bold">Español</span>
            <span className="font-bold">Namuy-wam</span>
            <span className="font-bold">Acción</span>
          </header>
          {pendingList?.map((record, index) => (
            <TextBox
              key={record.id}
              id={record.id}
              esValue={record.es}
              gumValue={record.gum}
              className={index % 2 === 0 ? "bg-white" : "bg-zinc-100"}
              onDelete={handleDelete}
            />
          ))}
        </section>
        <div className="flex items-center justify-center space-x-40"></div>
      </section>
      <div className="flex flex-col items-center justify-center bg-slate-100 pb">
        <div className="flex flex-row pt-2">
          <SelectPerPage onChangePerPage={handleChangeRowsPerPage} perPage={perPage}/>
          <div className="flex flex-row gap-x-2 p-5">
            <span className="font-base">Total de <strong>{count}</strong> registros</span>
          </div>
        </div>
        <Pagination
          total={totalPages}
          max={maxButtons}
          onChange={handleChangePage}
          current={page}
          perPage={perPage}
        />
      </div>
    </main>
  );
}
