import { Link, useLocation } from "react-router-dom";
import { utilities, users } from "../../data/links.js";
import userIcon from "../../assets/images/Register/user.png";
import { useEffect, useState } from "react";
import { Header } from "../Header/header.jsx";

export const Navbar = ({ isNotUserCard, user}) => {
  const location = useLocation();
  const [selectedLink, setSelectedLink] = useState(
    localStorage.getItem("selectedLink") || utilities[0].name
  );
  const [showMenuList, setShowMenuList] = useState(false);

  const menuUser = () => {
    setShowMenuList(!showMenuList);
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setSelectedLink(utilities[0].name);
    } else {
      const pathname = location.pathname.substring(1);
      const foundUtility = utilities.find(
        (utility) => utility.to === `/${pathname}`
      );
      if (foundUtility) {
        setSelectedLink(foundUtility.name);
        localStorage.setItem("selectedLink", foundUtility.name);
      }
    }
  }, [location]);

  const handleUtilityClick = (utility) => {
    setSelectedLink(utility.name);
    localStorage.setItem("selectedLink", utility.name);
  };

  return (
    <nav className="bg-zinc-200 sm:border-b-2 sm:border-white fixed top-0 w-full z-50 ">
      <div className="flex justify-between mx-2 sm:mx-6 lg:items-center">
        <div className="flex items-center justify-start sm:gap-x-1 w-[70%] sm:w-[100%]">
          {utilities.map((utility) => {
            return (
              <Link
                key={utility.name}
                to={utility.to}
                className={`flex flex-row px-2 py-2 sm:px-5 sm:py-5 items-center text-center font-bold h-[100%] ${
                  selectedLink === utility.name
                    ? "bg-sky-800 text-white"
                    : "hover:bg-zinc-300 text-black hover:text-black"
                }`}
                onClick={() => handleUtilityClick(utility)}
              >
                {utility.name}
              </Link>
            );
          })}
        </div>

        <div className="flex flex-col items-center justify-center sm:w-full xl:items-end">
        {!isNotUserCard ? (
          <Header logged={user?.profile} user={user} key={user?.profile}/>
        ): (
          <>
            <div className="hidden sm:block sm:flex sm:gap-x-2 sm:flex-row sm:my-2 sm:w-full sm:pr-2"> 
              {users.map((user) => {
                return (
                  <button className="flex bg-sky-800 hover:bg-blue-950 text-white rounded-lg px-3 py-1 items-center justify-center">
                    <Link to={user.to} key={user.name}>
                      {user.name}
                    </Link>
                  </button>
                );
              })}
            </div>
            <div className="block sm:hidden">
            <button 
                className="bg-zinc-100 rounded-full w-[6vh] h-[6vh] flex items-center justify-center shadow-2xl"
                onClick={menuUser}
              >
                <img src={userIcon} style={{ width: "20px", height: "20px" }} alt='userIcon'/>
              </button>

              {showMenuList && (
                <div className='px-4 bg-zinc-700 z-100 absolute top-[7vh] right-2 text-xs text-white py-4 flex gap-y-4 flex-col rounded-md'>
                   {users.map((user) => {
                return (
                  <button className="flex bg-white hover:bg-blue-950 text-black hover:text-white rounded-lg px-3 py-1 items-center justify-center">
                    <Link to={user.to} key={user.name}>
                      {user.name}
                    </Link>
                  </button>
                );
              })}
              	</div>
              )}
            </div>
          </>
        )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;