import WorldConnect from '../assets/icons/World-connect.svg';
import Language from '../assets/icons/Language.svg';
import Diversity from '../assets/icons/Diversity.svg';
import Decrease from '../assets/icons/Decrease.svg';

export const mision = [
    { id:1, text: 'Conectar a todas las personas del territorio Colombiano y el mundo.', icon: WorldConnect },
    { id:2, text: 'Proporcionar servicios de traducción precisos y eficientes.', icon: Language },
    { id:3, text: 'Creer en la importancia de la diversidad lingüística.', icon: Diversity },
    { id:4, text: 'Trabajar en la disminución de las barreras que impiden la comprensión mutua.', icon: Decrease },
]