import { createSlice } from "@reduxjs/toolkit";
import { getIndexList } from "../Actions/index.actions";

export const indexSlice = createSlice({
  name: "index",
  initialState: {
    data: null,
    loadingData: false,
    listPending: [],
    count: 0,
  },
  reducers: {
    deleteItem: (state, action) => {
      state.count--;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndexList.fulfilled, (state, action) => {
      state.listPending = action.payload.records;
      state.count = action.payload.count;
      state.loadingData = false;
    });
    builder.addCase(getIndexList.pending, (state, action) => {
      state.loadingData = true;
    });
    builder.addCase(getIndexList.rejected, (state, action) => {
      state.loadingData = false;
      state.error = action.payload;
    });
  },
});

export default indexSlice.reducer;
export const { deleteItem } = indexSlice.actions;
