import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { routes, privateRoutes } from "./routes";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Navbar from "../components/initial/navBar";
import Footer from "../components/initial/footer";
import {sessionRecover } from "../redux/Actions/session.actions";
import { Vortex } from "react-loader-spinner";
import moment from "moment";

moment.locale('es');

export default function InnerRouter() {
  const { session, loading, loadingSession } = useSelector((state) => state.session);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = String(location.pathname);

  const nameProfile = session?.username;
  localStorage.setItem('last-connection', moment(Date.now()).format('DD-MMM-yyyy'));
  const lastConnection = localStorage.getItem('last-connection');

  const user = {
    profile: 'USUARIO',
    nameProfile: nameProfile,
    lastLogin: lastConnection,
  }

  useEffect(() => {
    dispatch(sessionRecover()).then(() => {
      setIsLoading(false);
      // if (!sessionStorage.getItem("session")) navigate("/about");
    });
    return () => {
      localStorage.setItem("data", new Date());
    };
  }, [dispatch]);

  function PrivateRoute({ children }) {
    if (!session) {
      return <Navigate to="/login" />;
    }
    return children;
  }
  
  const isNotFooter = path === "/login" || path === "/register";

  if (loadingSession || isLoading) return <></>;
  return (
    <div className="flex flex-col h-screen">
      {loading && (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-white opacity-75">
        <Vortex
          height="80"
          width="80"
          colors={[
            "#e62269",
            "#e62269",
            "#002d7b",
            "#002d7b",
            "#000000",
            "#d1d1d1",
          ]}
          ariaLabel="vortex-loading"
        />
      </div>
      )}
      <header className="h-[6vh] w-full mb-2" >
        <Navbar isNotUserCard={!session} user={user} />
      </header>
      <article className="w-full mt-[3vh]">
        <Routes>
          {routes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={<route.element />}
            />
          ))}
          {privateRoutes.map((route) => (
            <Route
              key={route.name}
              path={route.path}
              element={
                <PrivateRoute>
                  <route.element />
                </PrivateRoute>
              }
            />
          ))}
        </Routes>
       <Footer isLogin={isNotFooter} />
      </article>
    </div>
  );
}
