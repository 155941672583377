import { createSlice } from "@reduxjs/toolkit";
import {
  sessionRecover,
  loginSession,
  logoutSession,
} from "../Actions/session.actions";

export const sessionSlice = createSlice({
  name: "session",
  initialState: {
    data: null,
    loading: false,
    loadingSession: true,
    error: null,
    user: null,
    session: null,
  },
  reducers: {
    closeSession: (state) => {
      state.session = null;
      state.user = null;
    },
    fetchDataStart: (state) => {
      state.loading = true; // eslint-disable-line no-param-reassign
      state.error = null; // eslint-disable-line no-param-reassign
    },
    fetchDataSuccess: (state, action) => {
      state.loading = false; // eslint-disable-line no-param-reassign
      state.data = action.payload; // eslint-disable-line no-param-reassign
      state.error = null; // eslint-disable-line no-param-reassign
    },
    fetchDataFailure: (state, action) => {
      state.loading = false; // eslint-disable-line no-param-reassign
      state.data = null; // eslint-disable-line no-param-reassign
      state.error = action.payload; // eslint-disable-line no-param-reassign
    },
  },
  extraReducers: (builder) => {
    // LoginSession
    builder.addCase(loginSession.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(loginSession.fulfilled, (state, action) => {
      state.loading = false;
      state.session = action.payload;
      state.error = null;
    });
    builder.addCase(loginSession.rejected, (state, action) => {
      state.loading = false;
      state.session = null;
      state.error = action.payload;
    });

    // session recover
    builder.addCase(sessionRecover.pending, (state) => {
      state.loadingSession = true;
    });
    builder.addCase(sessionRecover.fulfilled, (state, action) => {
      state.session = action.payload;
      state.user = action.payload.attributes;
      state.loadingSession = false;
    });
    builder.addCase(sessionRecover.rejected, (state, action) => {
      state.loadingSession = false;
      state.error = action.payload;
    });

    // logoutSession 
    builder.addCase(logoutSession.fulfilled, (state) => {
      state.session = null;
      state.user = null;
    });
  },
});

export const { fetchDataFailure, fetchDataStart, fetchDataSuccess, closeSession } = sessionSlice.actions;
export default sessionSlice.reducer;
