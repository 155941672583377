// /*eslint-disable */
import { useState, useEffect } from "react";
import { inputNames as originalInputNames } from "../../data/inputNames";
import BoxTranslater from "../../components/translater/box-translater";
import API from "../../services/API";
import PlayIcon from "@heroicons/react/24/outline/PlayIcon";
import { InfoPage } from "../../components/initial/info-page";
import ArrowPathIcon from "@heroicons/react/24/outline/ArrowPathIcon";
import ToolTipComponent from "../../components/initial/tool-tipComponent";

export default function Translater() {
  const [translatedData, setTranslatedData] = useState(null);
  const [inputValue, setInputValue] = useState({});
  const [inputNames, setInputNames] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [resetForm, setResetForm] = useState(false);
  const [gumValue, setGumValue] = useState("");
  const [esValue, setEsValue] = useState("");
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    setInputNames(originalInputNames);
  }, []);

  function handleValueChange(id, value) {
    setInputValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    if (id === "gum") {
      setGumValue(value);
    } else if (id === "es") {
      setEsValue(value);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
  }

  function reverseMapping() {
    const reversedInputNames = [...inputNames].reverse();
    setInputNames(reversedInputNames);
  }

  const handleClick = async () => {
    try {
      const dataToSend = {
        gum: inputValue["gum"],
        es: inputValue["es"],
      };

      if (!(dataToSend.gum && dataToSend.es)) {
        setSubmitted(false);
        return;
      }

      const data = await API("create", dataToSend);

      const updatedInputValues = {};
      inputNames.forEach((input) => {
        updatedInputValues[input.id] = "";
      });

      setInputValue(updatedInputValues);
      setTranslatedData(data?.data);
      setSubmitted(false);

      setResetForm(true);
      setTimeout(() => {
        setResetForm(false);
      }, 0);

      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
        setSubmitted(false);
      }, 2000);
    } catch (error) {
      console.error("Error fetching translations:", error);
    }
  };

  return (
    <main className="flex flex-col border w-full items-center bg-rgb-title h-[100%] mt-10 sm:mt-14">
      <div className="flex flex-col sm:h-20 items-center justify-center">
        <h1 className="text-5xl text-center sm:pt-2">TRADUCCIÓN</h1>
        <section className="flex justify-center py-4 sm:py-8 lg:py-10 px-12 items-center">
          <InfoPage
            parraph={`Aqui se crean las traducciones por parte de la comunidad
              Guambiana, lo que debes hacer es ingresar la frase en Español y su traducción en Namuy-Wam, luego, da click en el botón de
              Enviar. Esta traducción será verificada en la pestaña `}
            phrase={"Verificar Traducción"}
            mobileSize={4}
            laptopSize={6}
          />
        </section>
      </div>
      <section className="flex flex-col items-center w-full sm:space-y-2 sm:mt-4 lg:mt-8">
        {(showMessage) && (
          <div className="absolute top-20 right-5 bg-green-500 text-white py-2 px-4 rounded-md">
            Traducción creada exitosamente
          </div>
        )}
        <form onSubmit={handleSubmit} className="w-full px-10">
          {inputNames.map((inputName) => {
            return (
              <BoxTranslater
                key={inputName.name}
                nameLanguage={inputName.name}
                id={inputName.id}
                translatedValue={
                  inputName.id === "gum" ? translatedData?.data : null
                }
                onValueChange={handleValueChange}
                resetForm={resetForm}
              />
            );
          })}
          <div className="flex flex-row justify-center mt-10 gap-x-40 items-center">
            <ToolTipComponent
              placement="top"
              container={
                <span
                  style={{ textAlign: "left", color: "#fff", fontSize: "14px" }}
                >
                  Cambiar orden de traducción
                  <br />
                  Pemai tamarsrap mantѳ pѳntreiwan namuy wamwei purѳrilan
                </span>
              }
            >
              <button
                type="button"
                className="w-[7vh] h-[7vh] bg-opacity-70 hover:bg-blue-950 bg-sky-800 rounded-full 
                  border border-transparent flex items-center justify-center focus:outline-none text-slate-950 hover:text-white"
                onClick={reverseMapping}
              >
                {<ArrowPathIcon className="flex w-[5vh] h-[5vh]" />}
              </button>
            </ToolTipComponent>
            <ToolTipComponent
              placement="top"
              container={
                <span
                  style={{ textAlign: "left", color: "#fff", fontSize: "14px" }}
                >
                  Enviar traducción
                  <br />
                  Ѳrsrap Namuy wamwei purѳriwan
                </span>
              }
            >
              <button
                type="button"
                className="w-[8vh] h-[8vh] bg-opacity-70 hover:bg-blue-950 bg-sky-800 rounded-full 
                  border border-transparent flex items-center justify-center focus:outline-none text-slate-950 hover:text-white"
                onClick={handleClick}
                disabled={submitted || !(gumValue && esValue)}
                id="enviar"
              >
                {<PlayIcon className="flex w-[6vh] h-[6vh]" />}
              </button>
            </ToolTipComponent>
          </div>
        </form>
      </section>
    </main>
  );
}
