import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import PencilSquareIcon from "@heroicons/react/24/outline/PencilSquareIcon";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { sendDataUpdate } from "../../redux/Actions/update.actions";
import ToolTipComponent from "../../components/initial/tool-tipComponent";

export default function TextBox({
  esValue: initialEsValue,
  gumValue: initialGumValue,
  id,
  className,
  onDelete,
}) {
  const [esValue, setEsValue] = useState(initialEsValue);
  const [gumValue, setGumValue] = useState(initialGumValue);
  const [gumInputValue, setGumInputValue] = useState(initialGumValue);
  const [esEditable, setEsEditable] = useState(false);
  const [gumEditable, setGumEditable] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const dispatch = useDispatch();

  const inputClassName =
    "w-[10rem] sm:w-[100%] h-[5vh] sm:h-[7vh] border border-sky-700 rounded-lg p-2 resize-none";
  const inputDivClassName = "flex flex-row gap-x-2";

  const handleEsEditClick = () => {
    setEsEditable(!esEditable);
  };

  const handleGumEditClick = () => {
    setGumEditable(!gumEditable);
    const formatted = gumValue.replace(/o/g, "ѳ");
    setGumInputValue(formatted);
  };

  const handleGumChange = (e) => {
    const inputValue = e.target.value;
    const replaced = inputValue.replace(/o/g, "ѳ");

    setGumInputValue(replaced);
  };

  const handleClick = () => {
    setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      
    dispatch(
      sendDataUpdate({
        id: id,
        is_checked: true,
        esValue: esValue,
        gumValue: gumValue,
      })
      );
    onDelete(id);
    setEsEditable(false);
    setGumEditable(false);
    setGumValue(gumInputValue);

  };

  return (
    <form id={id} className={`w-[150%] sm:w-full flex flex-row sm:justify-center p-2 sm:px-6 lg:px-2 ${className}`}>
       {showMessage && (
          <div className="absolute top-20 right-5 bg-green-500 text-white py-2 px-4 rounded-md">
            Verificación realizada exitosamente
          </div>
        )}
      <div className="flex sm:gap-x-20">
        <div className={inputDivClassName}>
          <input
            name="box-translater"
            cols="30"
            rows="10"
            id="es"
            className={inputClassName}
            value={esValue}
            disabled={!esEditable}
            onChange={(e) => setEsValue(e.target.value)}
          ></input>
          <ToolTipComponent
            placement="top"
            container={
              <span
                style={{ textAlign: "left", color: "#fff", fontSize: "14px" }}
              >
                Editar traducción
                <br />
                Katelѳ tamara pѳramick namuy wamwei purѳriwan
              </span>
            }
          >
            <button
              type="button"
              className="text-blue-600 w-[6vh] h-[6vh]"
              onClick={handleEsEditClick}
            >
              <PencilSquareIcon className="flex w-[4vh] h-[4vh]" />
            </button>
          </ToolTipComponent>
        </div>
        <div className={inputDivClassName}>
          <input
            name="box-translater"
            cols="30"
            rows="10"
            id="gum"
            className={inputClassName}
            value={gumInputValue}
            disabled={!gumEditable}
            onChange={handleGumChange}
          ></input>
          <ToolTipComponent
            placement="top"
            container={
              <span
                style={{ textAlign: "left", color: "#fff", fontSize: "14px" }}
              >
                Editar traducción
                <br />
                Katelѳ tamara pѳramick namuy wamwei purѳriwan
              </span>
            }
          >
            <button
              type="button"
              className="text-blue-600 w-[6vh] h-[6vh]"
              onClick={handleGumEditClick}
            >
              <PencilSquareIcon className="flex w-[4vh] h-[4vh]" />
            </button>
          </ToolTipComponent>
        </div>
      </div>
      <div className="flex flex-row ml-8 sm:ml-40 gap-x-4 items-center">
        <ToolTipComponent
          placement="top"
          container={
            <span
              style={{ textAlign: "left", color: "#fff", fontSize: "14px" }}
            >
              Verificar traducción
              <br />
              Katѳle tap asamik namuy wamwei purѳriwan
            </span>
          }
        >
          <button
            type="button"
            className="w-[5vh] h-[5vh] sm:w-[6vh] sm:h-[6vh] bg-opacity-40 hover:bg-green-800 bg-green-800 rounded-full border border-transparent 
              flex items-center justify-center focus:outline-none text-slate-950 hover:text-white"
            onClick={handleClick}
          >
            <CheckIcon className="flex w-[4vh] h-[4vh]" />
          </button>
        </ToolTipComponent>
      </div>
    </form>
  );
}
