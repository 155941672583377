import React, { useState } from 'react'
import userIcon from "../../assets/images/Register/user.png";
import powerIcon from "../../assets/icons/logout.svg";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { closeSession } from '../../redux/Reducers/session.reducer';
import ToolTipComponent from '../initial/tool-tipComponent';

export const  Header = ({logged, user}) => { 
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showUserData, setShowUserData] = useState(false);

  const handlerUser = () => {
    setShowUserData(!showUserData);
  };

  const handleCloseSession = () => {
    sessionStorage.clear();
    dispatch(closeSession());
    navigate("/about");
  };

  return (
    <header className="sm:bg-sky-800 h-[80%] sm:rounded-lg sm:w-[98%] lg:w-[85%] sm:py-1 xl:w-[65%] flex justify-center items-center px-6 sm:px-0"> 
        {logged && (
          <>
            <header className='block sm:hidden felx items-center'>
              <button 
                className="bg-zinc-100 rounded-full w-[6vh] h-[6vh] flex items-center justify-center shadow-2xl"
                onClick={handlerUser}
              >
                <img src={userIcon} style={{ width: "20px", height: "20px" }} alt='userIcon'/>
              </button>
              {showUserData && (
                <div className='px-4 bg-zinc-700 z-100 absolute top-[7.5vh] right-2 text-xs text-white py-2 flex gap-y-2 flex-col rounded-md'>
                  <div className='flex flex-row gap-x-2'>
                    <span>Usuario: </span>
                    <span className='font-bold'>{user.nameProfile}</span>
                  </div>
                  <div className='flex flex-row gap-x-2'>
                    <span>Último acceso: </span>
                    <span className='font-bold'>{user.lastLogin}</span>
                  </div>
                  <div className='border-b border-gray-200 py-1'></div>
                  <div className='flex flex-row gap-x-2 py-1'>
                    <button 
                      className="bg-zinc-200 px-4 rounded-lg flex items-center justify-center transition duration-300 ease-in-out hover:shadow-white hover:shadow-2xl hover:bg-sky-200 text-black text-base gap-x-4"
                      onClick={handleCloseSession}
                      >
                      <img src={powerIcon} style={{ width: "15px", height: "15px" }} alt='powerIcon' />
                      Salir
                    </button>
                  </div>
              	</div>
              )}
            </header>
            <header className='hidden sm:block flex items-center justify-center gap-2 pl-2 w-full py-1 rounded-lg'>
              <div className='flex flex-row items-center gap-x-2 px-2'>
                <div className="bg-zinc-200 rounded-full w-[5vh] h-[5vh] flex items-center justify-center">
                    <img src={userIcon} style={{ width: "20px", height: "20px" }} alt='userIcon'/>
                </div>
                <p className='text-base text-white font-bold'>{user.nameProfile}</p>
                <div className="w-40 flex flex-col items-center border-r border-gray-300">
                  <span className='text-base text-white font-bold'>Último acceso</span>
                  <span className='text-xs text-white'>{user.lastLogin}</span>
                </div>
                <ToolTipComponent
                  placement="top"
                  container={
                    <span
                      style={{ textAlign: "left", color: "#fff", fontSize: "14px" }}
                    >
                      Salir
                    </span>
                  }
                > 
                <button 
                  className="bg-zinc-200 rounded-full w-[3vh] h-[3vh] flex items-center justify-center transition duration-300 ease-in-out hover:shadow-white hover:shadow-2xl hover:bg-sky-200"
                  onClick={handleCloseSession}
                >
                  <img src={powerIcon} style={{ width: "20px", height: "20px" }} alt='powerIcon' />
                </button>
                </ToolTipComponent>
              </div>
            </header>
           </>
        )}
    </header>
  )
}
