import React from "react";
import TeamAyte from "../../components/about/team-ayte";
import ImageSlider from "../../components/about/image-silder";
import MisionCard from "../../components/about/mision-card";
import SocialMedia from "../../components/about/social-media";
import { Ayte } from "../../data/images";
import { mision } from "../../data/texts";

import misak from "../../assets/images/misak/misak.jpg";
import misak1 from "../../assets/images/misak/misak1.webp";
import misak2 from "../../assets/images/misak/misak2.jpeg";
import misak3 from "../../assets/images/misak/Misak3.jpg";
import misak5 from "../../assets/images/misak/misak5.jpg";

export default function About() {
  return (
    <main className="mx-auto bg-white shadow-lg rounded-lg mt-14 sm:mt-7">
      <h1 className="text-5xl font-bold mb-4 flex justify-center text-center">
        Proyecto de Innovación de Traducción
      </h1>
      <header className="bg-rgb-about flex flex-col pb-2 sm:flex-row sm:pb-0">
        <div className="w-full sm:w-[300vh] mx-auto p-8">
          <ImageSlider images={[misak, misak1, misak2, misak3, misak5]} />
        </div>
        <div className="text-gray-200 text-xl sm:text-2xl px-4 sm:pl-4 sm:pr-12 flex flex-col items-center justify-center py-4">
          <p>
            Bienvenido a nuestro proyecto dedicado a facilitar la comunicación a
            través de la traducción de lenguas.
          </p>
          <br />
          <p>
            Nos esforzamos por romper barreras lingüísticas y hacer que la
            información sea accesible para todos.
          </p>
        </div>
      </header>
      <aside className="flex flex-col w-full bg-zinc-100 py-10">
        <h2 className="text-5xl font-bold mb-2 flex justify-center">
          Nuestra Misión
        </h2>
        <div className="mb-4 py-12 px-4 grid grid-cols-2 sm:flex sm:flex-row justify-center px-10 gap-x-4 gap-y-4 sm:gap-x-10 sm:gap-y-0">
          {mision.map((misionText) => {
            return (
              <MisionCard
                key={misionText.id}
                text={misionText.text}
                icon={misionText.icon}
              />
            );
          })}
        </div>
      </aside>
      <section className="flex flex-col sm:flex-col bg bg-rgb-about text-white">
        <div className="flex flex-col w-full p-4 items-center justify-center">
          <h2 className="text-2xl font-bold mb-2 flex justify-center">
            Equipo
          </h2>
          <p className="text-lg text-gray-100 mb-4 p-2 flex text-justify">
            Estamos formados por un equipo apasionado de desarrolladores y
            diseñadores comprometidos a crear soluciones innovadoras para la
            comunicación intercultural. Juntos, trabajamos para hacer del mundo
            un lugar donde todos puedan expresarse y entenderse,
            independientemente del idioma que hablen.
          </p>
        </div>
        <div className="grid grid-cols-2 sm:flex sm:flex-wrap sm:justify-center p-2 gap-x-6">
          {Ayte.map((ayte) => {
            return (
              <TeamAyte
                key={ayte.id}
                imagen={ayte.imagen}
                nombre={ayte.nombre}
                ciudad={ayte.ciudad}
                rol={ayte.rol}
              />
            );
          })}
        </div>
      </section>
      <footer className="flex flex-col bg-sky-950 items-center justify-center">
        <hr />
        <h2 className="text-2xl font-bold mb-2 mt-10 text-white">Contacto</h2>
        <div className="p-4 flex sm:flex-row items-center">
          <span className="text-gray-100">
            Si tienes preguntas, sugerencias o simplemente quieres ponerte en
            contacto con nosotros, no dudes en enviarnos un correo electrónico a {" "}
            <a href="mailto:digital@ayte.co" className="text-blue-500">
              info@Ayte.co
            </a>
            .
          </span>
        </div>
        <SocialMedia />
      </footer>
    </main>
  );
}
