export const users = [
  { name: "Iniciar sesión", to: "/login" },
  { name: "Registrarse", to: "/register" },
];

export const utilities = [
  { name: "Inicio", to: "/about" },
  { name: "Crear Traducción", to: "/translater" },
  { name: "Verificar Traducción", to: "/verifier" },
];
