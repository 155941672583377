import { Auth } from "aws-amplify";
import axios from "axios";

export default async function API(url, objectData) {
  try { 
    const user = await Auth.currentAuthenticatedUser();
    const session = user.signInUserSession;
    const accessToken = session.accessToken.jwtToken;

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    };

    const response = await axios.post(
        `https://rimg59dfz3.execute-api.us-east-1.amazonaws.com/dev/v1/translations/${url}`,
        {
          data: objectData
        },
       config
      );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data || error.message);
  }
}

const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.request.use(async (config) => {
  // const access_token = sessionStorage.getItem('token');
  // config.headers['Authorization'] = `Bearer ${access_token}`;
  config.headers['Content-Type'] = 'application/json';
  return config;
});
axiosApiInstance.interceptors.response.use((response) => {
  return response;
});
const getAccessToken = (token) => {
  sessionStorage.setItem('token', token);
  return Promise.resolve(token);
};

export { axiosApiInstance, getAccessToken };

  
  // para traducir: url = index
  // para actualizar: url = update
  // para crear: url = create
  