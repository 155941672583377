import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signUpAsync } from "../../redux/Actions/session.actions";
import Form from "./components/Form";
import LoginBackground from '../../assets/images/Login/backgroundLogin_Image.jpg';
import { errorAlert, successAlert } from "../../utils/AlertFunctions";
import { registerMessages } from "./registerMessages";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSignUp = async ({ email, password, name, phone_number }) => {
    try {
      await dispatch(
        signUpAsync({
          username: email,
          password: password,
          name: name,
          phone_number: phone_number,
        })
      );
      successAlert(registerMessages.sendEmailCode);
      navigate('/login');
    } catch (error) {
      errorAlert(error.message)
    }
  };

  return (
    <div 
      style={{
        backgroundImage: `url(${LoginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    className="justify-center h-[100%] py-[35%] sm:py-[13vh] flex items-center">
      <header className="bg-zinc-200 px-8 py-2 sm:py-10 sm:max-w-[390px] sm:min-w-[390px] rounded-lg">
        <h1 className="text-4xl font-bold mb-7 flex items-center justify-center">
          Regístrate
        </h1>
        <div className="flex flex-row space-x-2 items-center justify-start">
          <h5 className="text-center">¿Ya tienes una cuenta?</h5>
          <Link
            className="font-bold text-sky-800 hover:text-blue-950"
            to="/login"
          >
            Iniciar sesión
          </Link>
        </div>
        <Form handleSignUp={handleSignUp} />
      </header>
    </div>
  );
};

export default Register;
