import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import BoxInput from "../../../components/initial/box-input";
import phoneIcon from "../../../assets/images/Register/phone.png";
import userIcon from "../../../assets/images/Register/user.png";
import iconEmail from "../../../assets/icons/iconEmail.svg";
import passwordIcon from "../../../assets/icons/keyIcon.svg";
import { registerValidate } from "./schemaRegister";

const Form = ({ handleSignUp }) => {
  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerValidate),
    mode: "onChange",
  });

  const handleEmailChange = (e) => {
    const value = e.target.value.replaceAll(" ", "");
    setValue("email", value, { shouldValidate: true });
  };

  return (
    <form onSubmit={handleSubmit(handleSignUp)}>
      <div className="flex flex-col space-y-6 mb-6 mt-6">
        <BoxInput
          id="standard-basic"
          label="Nombre completo"
          Icon={userIcon}
          name="name"
          error={errors?.name !== undefined}
          helperText={errors?.name?.message || null}
          validate={getValues().name}
          setValue={setValue}
          trigger={trigger}
          variant="standard"
          type="text"
          required={true}
          register={register}
        />
        <BoxInput
          id="standard-basic"
          label="Número de celular"
          Icon={phoneIcon}
          name="phone_number"
          error={errors?.phone_number !== undefined}
          helperText={errors?.phone_number?.message || null}
          validate={getValues().phone_number}
          setValue={setValue}
          trigger={trigger}
          variant="standard"
          type="phone_number"
          required={true}
          register={register}
        />
        <BoxInput
          id="standard-basic"
          label="Correo electrónico"
          Icon={iconEmail}
          name="email"
          error={errors?.email !== undefined}
          helperText={errors?.email?.message || null}
          validate={getValues().email}
          setValue={setValue}
          trigger={trigger}
          variant="standard"
          type={"email"}
          required={true}
          register={register}
          onChange={handleEmailChange}
        />
        <BoxInput
          id="standard-basic"
          label="Contraseña"
          Icon={passwordIcon}
          name="password"
          error={errors?.password !== undefined}
          helperText={errors?.password?.message || null}
          validate={getValues().password}
          setValue={setValue}
          trigger={trigger}
          variant="standard"
          type="password"
          required={true}
          register={register}
        />
      </div>
      <button
        className="bg-sky-800 text-white font-bold hover:bg-blue-950 px-4 py-2 block w-full mt-4 rounded-lg"
        type="submit"
      >
        Continuar
      </button>
    </form>
  );
};

export default Form;
