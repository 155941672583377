import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import awsconfig from "../../aws-exports";

Auth.configure({
  ...awsconfig,
  authenticationFlowType: "USER_PASSWORD_AUTH",
  storage: sessionStorage,
});

//Iniciar sesión
export const loginSession = createAsyncThunk(
  "session/login",
  async ({ username, password }) => {
    try {
      const response = await Auth.signIn({ username, password,  params: {
        customScope: 'service/community',
      } });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// Recuperar sesión
export const sessionRecover = createAsyncThunk(
  "session/sessionRecover",
  async () => {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  }
);

// Cerrar sesión
export const logoutSession = createAsyncThunk("session/logout", async () => {
  try {
    const response = await Auth.signOut();
    return response;
  } catch (error) {
    throw error;
  }
});

export const signUpAsync = createAsyncThunk(
  "session/register",
  async ({ username, password, email, phone_number }) => {
    try {
      const response = await Auth.signUp({
        username,
        password,
        options: {
          userAttributes: {
            email,
            phone_number,
          },
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
