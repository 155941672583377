import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./router/AppRouter";
import { Provider } from "react-redux";
import { Vortex } from "react-loader-spinner";
import store from "./redux/store";
import { SnackbarProvider } from "notistack";
import "./App.css";

export default function App() {
  return (
    <React.Suspense 
      fallback={<Vortex
        height="80"
        width="80"
        colors={[
          "#e62269",
          "#e62269",
          "#002d7b",
          "#002d7b",
          "#000000",
          "#d1d1d1",
        ]}
        ariaLabel="vortex-loading"
    />}>
      <Provider store={store}>
        <SnackbarProvider maxSnack={3} />
        <BrowserRouter>
            <AppRouter />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  );
}
