/* eslint-disable*/
import { useState, useEffect } from "react";
import ChevronLeftIcon from "@heroicons/react/24/outline/ChevronLeftIcon";
import ChevronRightIcon from "@heroicons/react/24/outline/ChevronRightIcon";

export default function Pagination({
  left = true,
  max = 0,
  onChange = () => {},
  right = true,
  total,
  current,
  perPage
}) {
  const [page, setPage] = useState(1);

  const isFirstPage = current === 1;
  const isLastPage = current === total;

  const prevDisabled = isFirstPage;
  const nextDisabled = isLastPage;

  const pages = [...Array(Math.min(max || total, total)).keys()].map(
    (x) => x + 1 + (current > max ? current - max : 0)
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onChange(newPage);
  };

  const prevPage = () => {
    if (current > 1) {
      handlePageChange(current - 1);
    }
  };

  const nextPage = () => {
    if (current < total) {
      handlePageChange(current + 1);
    }
  };

  useEffect(() => {
    setPage(1);
  }, [perPage]);

  useEffect(() => {
    setPage(current);
  }, [current]);

  return (
    <div className="flex rounded-lg my-2 gap-x-2">
      {right && (
        <button
          disabled={prevDisabled}
          onClick={prevPage}
          className={`${
            prevDisabled
              ? "bg-gray-300 text-gray-500"
              : "w-4 border border-r-0 bg-white px-4 rounded-l-lg hover:bg-sky-800 hover:text-white"
          }`}
        >
          <svg class="w-4 h-4">
            <ChevronLeftIcon />
          </svg>
        </button>
      )}
      {pages.map((pg) => (
        <button
          key={pg}
          onClick={() => handlePageChange(pg)}
          className={`border-2 border-r-0 w-7 hover:bg-sky-200 ${
            current === pg && "bg-sky-700 text-white !important"
          }`}
        >
          {pg}
        </button>
      ))}
      {left && (
        <button
          disabled={nextDisabled}
          onClick={nextPage}
          className={`${
            nextDisabled
              ? "bg-gray-300 text-gray-500"
              : "w-8 border bg-white px-4 rounded-r-lg hover:bg-sky-800 hover:text-white"
          }`}
        >
          <svg class="w-4 h-4">
            <ChevronRightIcon />
          </svg>
        </button>
      )}
    </div>
  );
}
