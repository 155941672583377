import { createSlice } from "@reduxjs/toolkit";
import { sendDataUpdate } from "../Actions/update.actions";

export const updateSlice = createSlice({
  name: "update",
  initialState: {
    data: null,
    loadingData: false,
    listUpdate: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(sendDataUpdate.fulfilled, (state, action) => {
      state.listUpdate = action.payload.records;
      state.loadingData = false;
      state.count--;
    });
    builder.addCase(sendDataUpdate.pending, (state, action) => {
      state.loadingData = true;
    });
    builder.addCase(sendDataUpdate.rejected, (state, action) => {
      state.loadingData = false;
      state.error = action.payload;
    });
  },
});
export default updateSlice.reducer;
