import { configureStore } from "@reduxjs/toolkit";
import indexReducer from "./Reducers/index.reducer";
import updateReducer from "./Reducers/update.reducer";
import sessionReducer from "./Reducers/session.reducer";

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    index: indexReducer,
    update: updateReducer,
    session: sessionReducer,
  },
});

export default store;
