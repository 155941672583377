/* eslint-disable */
import * as yup from "yup";

export const registerRegex = {
  name: /^[a-zA-ZáéíóúÁÉÍÓÚÑñ\s]*$/,
  email:
    /^[A-Za-z0-9!#$%&'*/=?^_+`{|}~-]+(\.[A-Za-z0-9!#$%&'*/=?^_+`{|}~-]+)*@[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?(?:\.[A-Za-z]{2,})+$/,
  specialCharacters: /.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+.*/,
  mayusCharacter: /[A-Z]/,
  phone_number: /^[0-9]{3}[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4}$/,
};

const errorMessages = {
  emptyField: "Este campo no puede quedar vacío",
  specialChars: `No se permiten caracteres especiales '=)(/&%$#”"!||*{}[]+<>?¡¿^:;,°¬|`,
  invalidEmail: "No es un correo electrónico válido",
  invalidPhone: "No es un número de celular válido",
};

export const registerValidate = yup.object({
  name: yup.string().matches().required(errorMessages.emptyField),
  email: yup
    .string()
    .matches(registerRegex.email, errorMessages.invalidEmail)
    .min(6, "Este campo es minimo de 7 caracteres")
    .max(80, errorMessages.invalidEmail)
    .required(errorMessages.emptyField),
  phone_number: yup
    .string()
    .matches(registerRegex.phone_number, errorMessages.invalidPhone)
    .required(errorMessages.emptyField),
  password: yup
    .string()
    .required(errorMessages.emptyField)
    .min(8, "Debe contener al menos 8 caracteres")
    .matches(
      registerRegex.mayusCharacter,
      "Debe contener al menos una letra en mayúscula"
    )
    .matches(
      registerRegex.specialCharacters,
      "Debe contener al menos un caracter especial"
    ),
});
