import { yupResolver } from "@hookform/resolvers/yup";
import React from "react";
import { useForm } from "react-hook-form";
import BoxInput from "../../../components/initial/box-input";
import userIcon from "../../../assets/images/Register/user.png";
import passwordIcon from "../../../assets/icons/keyIcon.svg";
import { loginValidate } from "./schemaLogin";

const Form = ({ handleSignIn }) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginValidate),
    mode: "onChange",
  });

  const handleEmailChange = (e) => {
    const value = e.target.value.replaceAll(" ", "");
    setValue("email", value, { shouldValidate: true });
  };


  return (
    <form onSubmit={handleSubmit(handleSignIn)}>
      <div className="flex flex-col space-y-6 mb-6 mt-6">
        <BoxInput
          id="standard-basic"
          label="Correo electrónico"
          Icon={userIcon}
          name="email"
          error={errors?.email !== undefined}
          helperText={errors?.email?.message || null}
          validate={getValues().email}
          setValue={setValue}
          trigger={trigger}
          variant="standard"
          type="text"
          required={true}
          register={register}
          onChange={handleEmailChange}
        />
        <BoxInput
          id="standard-basic"
          label="Contraseña"
          Icon={passwordIcon}
          name="password"
          error={errors?.password !== undefined}
          helperText={errors?.password?.message || null}
          validate={getValues().password}
          setValue={setValue}
          trigger={trigger}
          variant="standard"
          type="password"
          required={true}
          register={register}
        />
      </div>
      <button
        className="bg-sky-800 text-white font-bold hover:bg-blue-950 px-4 py-2 block w-full mt-4 rounded-lg"
        type="submit"
        id="login"
      >
        Iniciar Sesion
      </button>
    </form>
  );
};

export default Form;
