import React from 'react'


export default function Footer({ isLogin}) {
    function getCurrentYear() {
        const currentDate = new Date();
        return currentDate.getFullYear();
    }
    
    return !isLogin && (
        <div className='flex flex-col w-full items-center justify-center mt-2 mb-2'>
            <span>Ayté {getCurrentYear()}. Todos los derechos reservados</span>
        </div>
    )
}
