/* eslint-disable */
import About from "../pages/about/About";
import Login from "../pages/login/Login";
import Register from "../pages/register/Register";
import Translater from "../pages/translater/Translater";
import Verifier from "../pages/verifier/Verifier";

export const routes = [
  {
    path: "/",
    name: "Home",
    element: About,
  },
  {
    path: "/about",
    name: "Home",
    element: About,
  },
  {
    path: "/login",
    name: "Iniciar Sesión",
    element: Login,
  },
  {
    path: "/register",
    name: "Registrarse",
    element: Register,
  },
  //   {
  //     path: "/translater",
  //     name: "Traductor",
  //     element: Translater,
  //   },
  //   {
  //     path: "/verifier",
  //     name: "Verificar traducción",
  //     element: Verifier,
  //   },
];

export const privateRoutes = [
  {
    path: "/translater",
    name: "Traductor",
    element: Translater,
  },
  {
    path: "/verifier",
    name: "Verificar traducción",
    element: Verifier,
  },
];
