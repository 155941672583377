import { createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import axios from "axios";

const BASE_URL =
  "https://rimg59dfz3.execute-api.us-east-1.amazonaws.com/dev/v1";

export const getIndexList = createAsyncThunk(
  "getIndexList",
  async ({ per_page, current_page }) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const session = user.signInUserSession;
      const accessToken = session.accessToken.jwtToken;
      
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      };
      const body = {
        data: {
          per_page,
          current_page,
        },
      };
      const response = await axios.post(
        `${BASE_URL}/translations/index`, body, config);
      return response.data.data;
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);
