import InfoIcon from "@heroicons/react/24/outline/InformationCircleIcon";

export const InfoPage = ({ parraph, phrase, mobileSize, laptopSize }) => {
  return (
    <div className="bg-rgb-info rounded-lg px-2 sm:py-1 flex flex-col sm:flex-row items-center gap-x-2 border border-sky-400">
      <InfoIcon className={`flex w-[${mobileSize}vh] h-[${mobileSize}vh] lg:w-[${laptopSize}vh] lg:h-[${laptopSize}vh] text-sky-600`} />
      <p>
        {parraph}
        <strong>{phrase}</strong>
      </p>
    </div>
  );
};
