import ArianImage from '../assets/images/ayteTeam/arian.webp';
import AndersonImage from '../assets/images/ayteTeam/anderson.webp';
import CamiloImage from '../assets/images/ayteTeam/camilo.webp';
import EstebanImage from '../assets/images/ayteTeam/esteban.webp';

export const Ayte = [
    {
        id: 1,
        nombre: 'Arian Castro',
        imagen: ArianImage,
        ciudad: 'Medellín',
        rol: 'Desarrollador'
    },
    {
        id: 2,
        nombre: 'Anderson Burgos',
        imagen: AndersonImage,
        ciudad: 'Popayán',
        rol: 'Desarrollador'
    },
    {
        id: 3,
        nombre: 'Esteban Cerón',
        imagen: EstebanImage,
        ciudad: 'Medellín',
        rol: 'Arquitecto de Soluciones'
    },
    {
        id: 4,
        nombre: 'Camilo Segura',
        imagen: CamiloImage,
        ciudad: 'Medellín',
        rol: 'Desarrollador'
    }
]
