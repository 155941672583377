/* eslint-disable */

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginSession } from "../../redux/Actions/session.actions";
import { useDispatch, useSelector } from "react-redux";
import Form from "./Components/Form";
import LoginBackground from '../../assets/images/Login/backgroundLogin_Image.jpg';
import { loginMessages } from "./loginMessages";
import {errorAlert, successAlert} from '../../utils/AlertFunctions';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { session, error } = useSelector((state) => state.session);

  const handleLogin = ({ email, password }) => {
    try {
      // dispatch(loginSession({ username: email, password: password }))
      dispatch(loginSession({username: email, password: password})).then((result) => {
        const errorCode = result?.error?.code;
        if (errorCode) {
          errorAlert(loginMessages[errorCode]);
          
        }
        else if (result?.payload?.attributes?.profile === session?.username) {
          successAlert(loginMessages.loginSuccess);
          navigate("/translater")  
        } 
        else {
          navigate("/");
        }

      })
    } catch (error) {
        throw error;
    } 
  }

  return (
    <div
      style={{
        backgroundImage: `url(${LoginBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="justify-center h-[100%] sm:h-full py-[20vh] flex items-center"
    >
      <header className="text-center bg-zinc-100 shadow-lg rounded-xl p-4 sm:p-6 min-w-[320px] sm:max-w-[390px] sm:min-w-[390px] box-border mx-32 flex flex-col justify-center">
        <h1 className="text-3xl font-bold mb-7 text-center">
          Inicia sesión en tu cuenta
        </h1>
        <div className="flex flex-row space-x-2 items-center justify-center">
          <h5 className="text-center">¿Aún no tienes una cuenta?</h5>
          <Link
            className="font-bold text-sky-800 hover:text-blue-950"
            to="/register"
          >
            Registrate
          </Link>
        </div>
        <div className="flex flex-col space-y-6 mb-6 mt-6">
          <Form handleSignIn={handleLogin} />
        </div>
      </header>
    </div>
  );
}
