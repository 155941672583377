import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

export default function MisionCard({ icon, text }) {
  return (
    <Card sx={{ maxWidth: 300 }}>
      <CardActionArea>
        <div className="flex py-4 justify-center">
          <div className="flex w-[10vh] h-[10vh] items-center justify-center rounded-full bg-sky-200">
            <img src={icon} alt="Equipo Ayté" className="w-[7vh] h-[10vh]" />
          </div>
        </div>
        <CardContent>
          <Typography
            gutterBottom
            variant="h6"
            className="flex px-4 text-center"
          >
            {text}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
